<template>
  <b-form
    @submit.prevent="validateForm"
  >
    <b-card
      no-body
    >
      <b-card-header class="pb-50">
        <h5>
          Permissions
        </h5>
      </b-card-header>
      <b-card-body>
        <b-row>
          <b-col
            md="6"
          >
            <!-- Field: Templat Name -->
            <b-form-group
              label="Template Name"
              label-for="templatename"
            >
              <b-form-input
                id="templatename"
                v-model="templatename"
                :readonly="true"
                name="templatename"
              />
            </b-form-group>
          </b-col>
        </b-row>
      </b-card-body>
      <b-table
        striped
        responsive
        :items="permissionsData"
        class="mb-0"
      >

        <template #cell(module)="data">
          {{ data.value }}
        </template>
        <template #cell()="data">
          <b-form-checkbox
            :id="data.value.name"
            v-model="selected"
            :name="data.value.name"
            :checked="data.value.value"
            :value="data.value.checkedvalue"
            :unchecked-value="data.value.uncheckedvalue"
            @change="updatePermissions()"
          />
        </template>

      </b-table>
      <b-card-body>
        <b-row>
          <b-col
            v-if="$can('update', 'abilitiestemplates')"
            md="2"
          >
            <!-- Action Buttons -->
            <b-button
              variant="primary"
              class="mb-1 mb-sm-0 mr-0 mr-sm-1"
              block
              type="submit"
            >
              Save Changes
            </b-button>
          </b-col>
          <b-col
            v-if="$can('delete', 'abilitiestemplates')"
            md="2"
          >
            <!-- Action Buttons -->
            <b-button
              variant="primary"
              class="mb-1 mb-sm-0 mr-0 mr-sm-1"
              block
              @click="deleteTemplate"
            >
              Delete Template
            </b-button>
          </b-col>
        </b-row>
      </b-card-body>
    </b-card>
  </b-form>
</template>

<script>
import {
  BCard, BTable, BCardBody, BCardHeader, BFormCheckbox, BRow, BCol, BFormGroup, BFormInput, BForm, BButton,
} from 'bootstrap-vue'
// Notification
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

import store from '@/store'

export default {
  components: {
    BCard,
    BTable,
    BCardBody,
    BFormCheckbox,
    BCardHeader,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BForm,
    BButton,
  },
  props: {
    templateData: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      selected: [],
      permissionsData: [],
      templatename: '',
      theTemplateData: this.templateData,
    }
  },
  created() {
    console.log('created')
    console.log(this.theTemplateData)
    const abilitiesOptions = store.state.appSettings.abilities_options
    // Remove Developer abilities if in staging or production
    if (process.env.VUE_APP_ENVIRONMENT_NAME === 'staging' || process.env.VUE_APP_ENVIRONMENT_NAME === 'production') {
      abilitiesOptions.forEach((item, index) => {
        if (item.subject === 'developer') {
          abilitiesOptions.splice(index, 1)
        }
      })
    }
    this.templatename = this.theTemplateData.templatename
    let tmp = {}

    abilitiesOptions.forEach(item => {
      tmp = { module: item.subject }
      tmp.read = {
        name: `${item.subject}-read`, value: `${item.subject}-read-false`, checkedvalue: `${item.subject}-read-true`, uncheckedvalue: `${item.subject}-read-false`,
      }
      tmp.create = {
        name: `${item.subject}-create`, value: `${item.subject}-create-false`, checkedvalue: `${item.subject}-create-true`, uncheckedvalue: `${item.subject}-create-false`,
      }
      tmp.update = {
        name: `${item.subject}-update`, value: `${item.subject}-update-false`, checkedvalue: `${item.subject}-update-true`, uncheckedvalue: `${item.subject}-update-false`,
      }
      tmp.delete = {
        name: `${item.subject}-delete`, value: `${item.subject}-delete-false`, checkedvalue: `${item.subject}-delete-true`, uncheckedvalue: `${item.subject}-delete-false`,
      }
      if (typeof this.theTemplateData.templateJSON !== 'undefined' && this.theTemplateData.templateJSON.length > 0) {
        this.theTemplateData.templateJSON.forEach(item2 => {
          if (item2.subject === item.subject) {
            item2.actions.forEach(item3 => {
              if (item3.action === 'read') {
                tmp.read = {
                  name: `${item.subject}-read`, value: `${item.subject}-read-true`, checkedvalue: `${item.subject}-read-true`, uncheckedvalue: `${item.subject}-read-false`,
                }
                this.selected.push(`${item.subject}-read-true`)
              } else if (item3.action === 'create') {
                tmp.create = {
                  name: `${item.subject}-create`, value: `${item.subject}-create-true`, checkedvalue: `${item.subject}-create-true`, uncheckedvalue: `${item.subject}-create-false`,
                }
                this.selected.push(`${item.subject}-create-true`)
              } else if (item3.action === 'update') {
                tmp.update = {
                  name: `${item.subject}-update`, value: `${item.subject}-update-true`, checkedvalue: `${item.subject}-update-true`, uncheckedvalue: `${item.subject}-update-false`,
                }
                this.selected.push(`${item.subject}-update-true`)
              } else if (item3.action === 'delete') {
                tmp.delete = {
                  name: `${item.subject}-delete`, value: `${item.subject}-delete-true`, checkedvalue: `${item.subject}-delete-true`, uncheckedvalue: `${item.subject}-delete-false`,
                }
                this.selected.push(`${item.subject}-delete-true`)
              }
            })
          }
        })
      }
      this.permissionsData.push(tmp)
    })
  },
  methods: {
    updatePermissions() {
      const abilitiesOptions = store.state.appSettings.abilities_options
      // Remove Developer abilities if in staging or production
      if (process.env.VUE_APP_ENVIRONMENT_NAME === 'staging' || process.env.VUE_APP_ENVIRONMENT_NAME === 'production') {
        abilitiesOptions.forEach((item, index) => {
          if (item.subject === 'developer') {
            abilitiesOptions.splice(index, 1)
          }
        })
      }
      const abilities = []
      let foundActions = false
      let tmp = {}
      // sort the list of selected abilities and loop through to format in final JSON format before saving
      this.selected.sort()
      abilitiesOptions.forEach(item => {
        tmp = { subject: item.subject, actions: [] }
        foundActions = false
        this.selected.forEach(item1 => {
          const parts = item1.split('-')
          if (parts[0] === item.subject) {
            tmp.actions.push({ action: parts[1] })
            foundActions = true
          }
        })
        if (foundActions) {
          abilities.push(tmp)
        }
      })
      return abilities
    },
    validateForm() {
      // Update the user data before sending
      if (this.$ability.can('update', 'abilitiestemplates')) {
        this.theTemplateData.templateJSON = this.updatePermissions()
        console.log(this.theTemplateData)
        // Save the new template
        store.dispatch('app-abilities-templates/updateTemplate', this.theTemplateData)
          .then(response => {
            console.log(response)
            this.$toast({
              component: ToastificationContent,
              position: 'top-right',
              props: {
                title: 'Template Updated',
                icon: 'CheckIcon',
                variant: 'success',
              },
            })
          })
          .catch(error => {
            console.log(error)
            let theErrorStr = 'The following error(s) where received:\n'
            if (typeof error.response.data.errors !== 'undefined') {
              error.response.data.errors.forEach(item => {
                theErrorStr += `${item.message}\n`
              })
            }
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Error updating template information.',
                icon: 'AlertTriangleIcon',
                variant: 'danger',
                text: theErrorStr,
              },
            })
          })
      }
    },
    deleteTemplate() {
      if (this.$ability.can('delete', 'abilitiestemplates')) {
        this.boxTwo = ''
        this.$bvModal
          .msgBoxConfirm('Are you sure you want to delete this template? This action cannot be undone.', {
            title: 'Delete Template',
            size: 'sm',
            okVariant: 'primary',
            okTitle: 'Yes',
            cancelTitle: 'No',
            cancelVariant: 'outline-secondary',
            hideHeaderClose: false,
            centered: true,
          })
          .then(value => {
            if (value) {
              console.log('deleting item')
              store.dispatch('app-abilities-templates/deleteTemplate', this.theTemplateData)
                .then(response => {
                  console.log(response)
                  const nextRout = { name: 'apps-abilities-list' }
                  // Route user
                  this.$router.replace(nextRout)
                    .then(() => {
                      this.$toast({
                        component: ToastificationContent,
                        position: 'top-right',
                        props: {
                          title: 'Template Deleted',
                          icon: 'CheckIcon',
                          variant: 'success',
                        },
                      })
                    })
                })
                .catch(error => {
                  console.log(error)
                  let theErrorStr = 'The following error(s) where received:\n'
                  if (typeof error.response.data.errors !== 'undefined') {
                    error.response.data.errors.forEach(item => {
                      theErrorStr += `${item.message}\n`
                    })
                  }
                  this.$toast({
                    component: ToastificationContent,
                    props: {
                      title: 'Error deleting template information.',
                      icon: 'AlertTriangleIcon',
                      variant: 'danger',
                      text: theErrorStr,
                    },
                  })
                })
            }
          })
      }
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
