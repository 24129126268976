import { API } from 'aws-amplify'
import store from '@/store'
import ability from '../../../libs/acl/ability'

export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    fetchTemplates(ctx, qvars) {
      return new Promise((resolve, reject) => {
        if (ability.can('read', 'abilitiestemplates')) {
          const { forceRefresh } = qvars
          store.state.abilitiestemplates.fetchingAbilitiesTemplatesList = true
          const d1 = new Date()
          const timeDiff = d1.getTime()
          let totalItems = 0

          const resortItems = items => {
            if (qvars.sortDesc) {
              items.sort((a, b) => {
                console.log('sort anything desc')
                // console.log(a)
                // console.log(b)
                // console.log(theSortBy)
                return b.templatename.localeCompare(a.templatename)
              })
            } else {
              items.sort((a, b) => {
                console.log('sort anything asc')
                // console.log(a)
                // console.log(b)
                // console.log(theSortBy)
                return a.templatename.localeCompare(b.templatename)
              })
            }
          }
          const paginateItems = item => {
            const start = (qvars.page - 1) * qvars.perPage
            const end = start + qvars.perPage
            return item.slice(start, end)
          }
          const resortFilterItems = item => {
            const resultItems = item
            totalItems = resultItems.length
            // resort users
            resortItems(resultItems)
            // Apply pagination and return
            return paginateItems(resultItems)
          }

          // Check for caching
          if (
            forceRefresh
            || localStorage.getItem('abilities_templates') === null
            || parseInt(localStorage.getItem('abilities_templates_lastRefresh'), 10) + 10080000 < timeDiff // cache for 7 days
          ) {
            console.log('Refreshing Templates')
            const apiName = 'APIGateway'
            const path = '/abilitytemplates'
            const myInit = { response: true }

            API.get(apiName, path, myInit)
              .then(response => {
                const theResponse = response
                const templates = response.data
                // Store User data in local storage
                localStorage.setItem('abilities_templates', JSON.stringify(templates))
                localStorage.setItem('abilities_templates_lastRefresh', d1.getTime())
                theResponse.data.templates = resortFilterItems(templates)
                theResponse.data.totalTemplates = totalItems
                console.log(theResponse)
                store.state.abilitiestemplates.fetchingAbilitiesTemplatesList = false
                resolve(theResponse)
              })
              .catch(error => {
                store.state.abilitiestemplates.fetchingAbilitiesTemplatesList = false
                reject(error)
                if (error === 'No current user') {
                  this.$router.replace({ name: 'auth-login' })
                }
              })
          } else {
            console.log('Using Cached templates Data')
            const theResponse = { data: {} }
            const templateData = JSON.parse(localStorage.getItem('abilities_templates'))
            theResponse.data.templates = resortFilterItems(templateData)
            theResponse.data.totalTemplates = totalItems
            console.log(theResponse)
            store.state.abilitiestemplates.fetchingAbilitiesTemplatesList = false
            resolve(theResponse)
          }
        } else {
          reject()
        }
      })
    },
    addTemplate(ctx, templateData) {
      return new Promise((resolve, reject) => {
        if (ability.can('create', 'abilitiestemplates')) {
          const apiName = 'APIGateway'
          const path = '/abilitytemplates'

          API.post(apiName, path, { body: templateData })
            .then(response => {
              // Add new template to the local storage to avoid call to backend
              const newAbilitiesTemplates = JSON.parse(localStorage.getItem('abilities_templates'))
              newAbilitiesTemplates.push(templateData)
              localStorage.setItem('abilities_templates', JSON.stringify(newAbilitiesTemplates))
              resolve(response)
            })
            .catch(error => {
              console.log(error)
              reject(error)
              if (error === 'No current user') {
                this.$router.replace({ name: 'auth-login' })
              }
            })
        } else {
          reject()
        }
      })
    },
    updateTemplate(ctx, templateData) {
      return new Promise((resolve, reject) => {
        if (ability.can('update', 'abilitiestemplates')) {
          const apiName = 'APIGateway'
          const path = '/abilitytemplates'

          API.patch(apiName, path, { body: templateData })
            .then(response => {
              // Update template in the local storage to avoid call to backend
              const oldAbilitiesTemplates = JSON.parse(localStorage.getItem('abilities_templates'))
              const newAbilitiesTemplates = []
              oldAbilitiesTemplates.forEach(item => {
                if (item.templatename === templateData.templatename) {
                  newAbilitiesTemplates.push(templateData)
                } else {
                  newAbilitiesTemplates.push(item)
                }
              })
              localStorage.setItem('abilities_templates', JSON.stringify(newAbilitiesTemplates))
              resolve(response)
            })
            .catch(error => {
              console.log(error)
              reject(error)
              if (error === 'No current user') {
                this.$router.replace({ name: 'auth-login' })
              }
            })
        } else {
          reject()
        }
      })
    },
    deleteTemplate(ctx, templateData) {
      return new Promise((resolve, reject) => {
        if (ability.can('delete', 'abilitiestemplates')) {
          console.log('deleteTemplate')
          const apiName = 'APIGateway'
          const path = '/abilitytemplates'
          const theBody = { templatename: templateData.templatename }

          API.del(apiName, path, { body: theBody })
            .then(response => {
              // Delete template from the local storage to avoid call to backend
              const oldAbilitiesTemplates = JSON.parse(localStorage.getItem('abilities_templates'))
              const newAbilitiesTemplates = []
              oldAbilitiesTemplates.forEach(item => {
                if (item.templatename !== templateData.templatename) {
                  newAbilitiesTemplates.push(item)
                }
              })
              localStorage.setItem('abilities_templates', JSON.stringify(newAbilitiesTemplates))
              resolve(response)
            })
            .catch(error => {
              console.log(error)
              reject(error)
              if (error === 'No current user') {
                this.$router.replace({ name: 'auth-login' })
              }
            })
        } else {
          reject()
        }
      })
    },
  },
}
